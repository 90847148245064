import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import SecondaryHero from "../../components/secondary-hero";
import EventSection from "../../components/event-section";

const RecycleYourItemsPage = ({ transitionStatus }) => {
	const data = useStaticQuery(graphql`
		query {
			heroImgMain: wpMediaItem(title: { eq: "Home Hero Main" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			wpPageContent(id: { eq: "cG9zdDo0NzY=" }) {
				content
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "How Can I Help",
				item: {
					url: `${siteUrl}/how-can-i-help`,
					id: `${siteUrl}/how-can-i-help`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "We Collect",
				item: {
					url: `${siteUrl}/how-can-i-help/we-collect`,
					id: `${siteUrl}/how-can-i-help/we-collect`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="We Collect | Friends of Eastbourne Hospital"
				description="There are so many items we can recycle, please do check this list and get in touch if you have anything you would like to donate, or any questions regarding what we collect."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/how-can-i-help/we-collect`,
					title: "We Collect | Friends of Eastbourne Hospital",
					description:
						"There are so many items we can recycle, please do check this list and get in touch if you have anything you would like to donate, or any questions regarding what we collect.",
					images: [
						{
							url: `${data.heroImgMain?.localFile.publicURL}`,
							width: `${data.heroImgMain?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImgMain?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImgMain?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section className="pb-5">
					<SecondaryHero
						title="Recycle your items"
						description={
							<span>
								There are so many items we can recycle, please do check this
								list and
								<br className="d-none d-md-block" /> get in touch if you have
								anything you would like to donate, or any
								<br className="d-none d-md-block" /> questions regarding what we
								collect.
							</span>
						}
					/>
				</section>
				<section className="pb-md-7 pb-5">
					<Container>
						<Row>
							<Col className="page-content we-collect">
								{parse(data.wpPageContent.content)}
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<EventSection />
				</section>
			</Layout>
		</>
	);
};

export default RecycleYourItemsPage;
